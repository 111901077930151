<template>
    <el-container>
        <el-row style="width: 100%">
            <el-col :span="24">
                <div class="cardTitle">项目列表</div>
            </el-col>
            <el-col :span="24">
                <el-table :data="list" style="width: 100%">
                <el-table-column prop="name" label="项目名称" sortable></el-table-column>
                <el-table-column prop="content" label="描述" sortable></el-table-column>
                <el-table-column prop="createTime" label="创建时间" sortable></el-table-column>
                <el-table-column fixed="right" label="操作">
                    <template slot-scope="scope">
                    <el-button
                        @click="getSubmitHistory(scope.row.guid)"
                        type="primary"
                        size="mini"
                    >详情
                    </el-button>
                    </template>
                </el-table-column>
                </el-table>
            </el-col>
        </el-row>
    </el-container>
</template>

<script>
export default {
    data() {
        return {
            userInfo: [],
            list: [],
            dialogVisible: false,
            question: [],
            radio: ''
        }
    },
    mounted() {
        let self = this
        self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        self.getList()
    },
    methods: {
        getList() {
            let self = this;
            self.$axios
            .get(
                "/project/list3",
                {
                headers: { token: self.userInfo.token },
                }
            )
            .then(function (response) {
                self.list = response.data.data
                console.log(response);
            })
            .catch(function (error) {
                self.$message({
                message: "审核列表获取失败：" + error.response.data.message,
                type: "warning",
                });
            });
        },
        getSubmitHistory(guid) {
            let self = this;
            self.$router.push({ name: "SubmitHistoryList", query: { guid: guid } });
        },
        submitStatus(radio) {
            console.log(radio);
            let self = this
            self.question.detailStatus = '1'
        },
        handleSizeChange(val) {
            this.pageSize = val * 1;
            this.getUserPageNum()
        },
        handleCurrentChange(val) {
            this.getUserPageNum(val)
        },
        getUserPageNum(val) {
            this.getList(val);
        }
    }
}
</script>

<style>
    /* .table{
      margin-bottom: 10px;
    }
    .table table{
      border-right: 1px solid #EBEEF5;
      border-bottom: 1px solid #EBEEF5;
      border-collapse: collapse;
    }
    .table th,.table td{
      border-left: 1px solid #EBEEF5;
      border-top: 1px solid #EBEEF5;
      padding: 10px;
    } */
</style>